import React from "react";
import Navbar from "../sections/Navbar/Navbar";
import ImgContentTemplate from "../components/old/ImgContentTemplate/ImgContentTemplate";
// @ts-ignore
import MeilleursImg from "../assets/img/old/meilleursHero.png";
// @ts-ignore
import MeilleursStudyImg from "../assets/img/old/meilleursStudy.png";
// @ts-ignore
import MeilleursLogo from "../assets/img/old/meilleursLogo.png";
// @ts-ignore
import locationIcon from "../assets/img/old/icons/majesticons_map-marker-line.png";

// @ts-ignore
import userIcon from "../assets/img/old/icons/majesticons_users-line.png";

// @ts-ignore
import buildingIcon from "../assets/img/old/icons/icon-park-outline_factory-building.png";

// @ts-ignore
import l1Icon from "../assets/img/old/icons/icon-park-outline_flag.png";

// @ts-ignore
import l2Icon from "../assets/img/old/icons/icon-park-outline_target-two.png";

// @ts-ignore
import l3Icon from "../assets/img/old/icons/majesticons_scan-user-line.png";

// @ts-ignore
import l4Icon from "../assets/img/old/icons/icon-park-outline_double-up.png";

// @ts-ignore
import Seo from "../utils/seo";
import CompanyIconCard from "../components/old/CompanyIconCard/CompanyIconCard";
import Objectives from "../sections/old/Objectives/Objectives";
import CompanyCaseStudyTemplate from "../components/old/CompanyCaseStudyTemplate/CompanyCaseStudyTemplate";
import What from "../sections/old/What/What";
import { Link } from "gatsby";
import Footer from "../sections/Footer";
// @ts-ignore
import Layout from "../layouts";

const MeilleursAgentsFr = () => {
  return (
    <>
      <Seo title="Coachello - Case Studies" />
      <Layout>
        <ImgContentTemplate
          img={MeilleursImg}
          title="Meilleurs Agents"
          des={
            <>
              <p>
                MeilleursAgents.com est un service immobilier innovant pour les particuliers vendant leur propriété. Fondée en 2008, MeilleursAgents.com a commencé avec seulement quatre employés, les fondateurs. Aujourd'hui, l'entreprise compte plus de trois cents employés, plus de 10 000 agences partenaires et est devenue le leader en matière de tarification et d'estimation immobilières en ligne, avec une part de marché nationale de 81 %.
              </p>
              <div className="flex justify-between py-4">
                <CompanyIconCard
                  icon={locationIcon}
                  header={"Paris, France"}
                  des={"Emplacement"}
                />
                <CompanyIconCard
                  icon={userIcon}
                  header={"300+"}
                  des={"Taille de l'entreprise"}
                />
                <CompanyIconCard
                  icon={buildingIcon}
                  header={"Immobilier"}
                  des={"L'industrie"}
                />
              </div>

              <p>
                Basée à Paris, en pleine croissance, récemment acquise par un grand groupe international et déjà leader sur le marché. Nous ne divulguons pas tous les détails, mais il est sûr de dire qu'il y avait suffisamment d'éléments pour mettre en place un programme de coaching en leadership significatif pour leur population de cadres. C'est exactement ce que nous avons fait et ce qui nous a fait aimer Meilleurs Agents.
              </p>
            </>
          }
        />
        <Objectives header="Objectifs">
          <div>
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l1Icon} />
                <h4 className={"m-0"}>Améliorer les compétences en matière de gestion et de leadership</h4>
              </li>
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l2Icon} />
                <h4>Soutenir tous les gestionnaires de manière personnalisée </h4>
              </li>
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l3Icon} />
                <h4> Susciter un changement de comportement </h4>
              </li>
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l4Icon} />
                <h4> Intégrer le coaching dans un programme plus large de L&D</h4>
              </li>
            </ul>
          </div>
        </Objectives>
        <CompanyCaseStudyTemplate
          img={MeilleursStudyImg}
          logo={MeilleursLogo}
          header="Comment les Meilleurs Agents ont mis en place le Programme de Coaching en Leadership de Coachello pour soutenir et améliorer les compétences de leurs managers."
          rightContentE={
            <>
              <p
                style={{ letterSpacing: "0.28em" }}
                className="text-[#FD0054] text-[18px] uppercase"
              >
                Solution
              </p>
              <p>
                Dans ce contexte, Meilleurs Agents a réalisé qu'il était crucial d'investir dans ses managers, d'accroître leur engagement, de les soutenir pour devenir de meilleurs leaders, de relever leurs défis et de les aider à naviguer dans une période de changement.
              </p>
              <p>
                C'est pourquoi Aminata Pelletier et Clarisse Berriau, responsables des ressources humaines chez Meilleurs Agents, ont voulu créer un parcours d'apprentissage incluant du coaching et rendre les managers autonomes dans leur développement personnel. Le coaching n'était accessible qu'aux cadres supérieurs, et elles voulaient le généraliser à un plus grand nombre de managers grâce à une solution de coaching.
              </p>

              <br />
              <p
                style={{ letterSpacing: "0.28em" }}
                className="text-[#FD0054] text-[18px] uppercase"
              >
                Caractéristiques
              </p>
              <p>
                Le programme Coachello Leadership a été rendu disponible à 40 managers chez Meilleurs Agents en France.
              </p>

              <p>Caractéristiques:</p>
              <ul className="leading-6">
                <li>Accès à l'application Slack pour tous les employés</li>
                <li>Programme de coaching en leadership (6 sessions chacune)</li>
                <li>Accord tripartite avec M+1 pour établir le cadre lors de la première session</li>
                <li>Informations sur les performances par rapport aux objectifs fixés</li>
                <li>Appel d'orientation avec le responsable du coaching</li>
              </ul>

              <br />

              <p
                style={{ letterSpacing: "0.28em" }}
                className="text-[#FD0054] text-[18px] uppercase"
              >
                Résultats
              </p>
              <p>
                Depuis le lancement du Programme de Coaching en Leadership, les participants ont montré des progrès incroyables. Après seulement trois mois, le programme s'est étendu à d'autres managers au sein de l'entreprise. Les cinq principaux sujets de coaching étaient : le leadership, la gestion, la communication, les relations et la signification au travail.
              </p>
              <p>
                La mise en œuvre de ce programme a entraîné les résultats suivants :
              </p>
              <ul className="leading-6">
                <li>
                  96% des managers sont satisfaits de Coachello et recommandent le coaching à d'autres managers.
                </li>
                <li>
                  95% des managers ont déclaré que Coachello a amélioré leurs compétences en leadership.
                </li>
                <li>
                  87% des managers ont déclaré que Coachello les a aidés à être plus engagés envers l'entreprise.
                </li>
                <li>
                  77% des collaborateurs ont ressenti que Coachello avait un impact positif sur leur communication et leurs relations avec les autres.
                </li>
              </ul>

              <br />
              <Link
                target="_blank"
                to={"https://meetings.hubspot.com/quentin-bouche/intro-coachello"}
              >
                <button className="Navbar-button">
                  Apprenez en 20 minutes ce que nous pouvons faire pour vous.
                </button>
              </Link>
            </>
          }
        />
        <What />
      </Layout>
    </>
  );
};

export default MeilleursAgentsFr;
